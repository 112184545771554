import React from 'react';
import {Link} from 'react-router-dom';

function HomePage({user}){
	return (
		<div className='homepg'>
		<div className="homepage-background"></div>
				<div style={{paddingTop: '50px', paddingBottom: '50px'}} className="article">


{/*
<h2>The last few years have seen us come up with some of humanity's most powerful new technologies in centuries - from AI to VR. Marketers can use these to transform our world for the better. From how we consume, to how we think, to how we live with one another.</h2>
<h2 style={{marginTop: '30px'}}>In this blog, I unpack new technologies and their implications for brands.</h2>
<p>The big changes include AI, aging societies and quantum computing.</p>
<p>The big changes include AI, aging societies in developed countries, the wealth and power of different countries, robots, biotechnology, rising individual wealth levels, wealth distribution, cultural norms, global institutions, climate policies, and quantum computing.</p>
<p>By using this analysis, decision makers can make better choices for companies and governments - making for a better future.</p>
*/}


<h2 style={{marginTop: '40px', textDecoration: 'underline'}}>Articles</h2>


<p style={{marginTop: '40px'}}><a href="/the-off-platform-advertising-opportunity"><span style={{color: 'grey'}}>/</span> The Off-Platform Advertising Opportunity</a></p>
<p style={{marginTop: '20px'}}><a href="/what-deepseek-means-for-cmos-invest-in-creative"><span style={{color: 'grey'}}>/</span> What DeepSeek Means For CMOs - Invest In Creative</a></p>
<p style={{marginTop: '20px'}}><a href="/interview-artist-and-creative-director-luis-fabra-talks-about-the-future-of-the-industry"><span style={{color: 'grey'}}>/</span> Interview: Artist And Creative Director Luis Fabra Talks About The Future Of The Industry</a></p>
<p style={{marginTop: '20px'}}><a href="/2025-will-be-a-good-year-for-advertising-its-all-about-the-economy-and-ai"><span style={{color: 'grey'}}>/</span> 2025 Will Be A Good Year For Advertising - It's All About The Economy & AI</a></p>
<p style={{marginTop: '20px'}}><a href="/winner-winner-video-game-dinner-the-ultimate-new-tool-for-advertisers"><span style={{color: 'grey'}}>/</span> More Advertisers Will Start Making Video Games Now That Costs Are Falling</a></p>
<p style={{marginTop: '20px'}}><a href="/interview-with-rod-judkins-bestselling-author-of-the-art-of-creative-thinking-on-the-future"><span style={{color: 'grey'}}>/</span> Interview with Rod Judkins - Bestselling Author of The Art of Creative Thinking on the Future</a></p>
<p style={{marginTop: '20px'}}><a href="/what-the-new-openai-o1-models-will-mean-for-advertising"><span style={{color: 'grey'}}>/</span> What The New OpenAl 01 Models Will Mean For Advertising</a></p>

<p style={{marginTop: '20px'}}><a href="/skibidi-to-sigma-gen-alpha-slang-psychology"><span style={{color: 'grey'}}>/</span> Skibidi to Sigma. Gen Alpha Slang & Psychology</a></p>
		{/*
		{user ? (
			<p>Welcome, {user.email}! {!user.isPaidSubscriber && <Link to='/subscribe'>Subscribe</Link>}</p>) : (
				<p><Link to="login">Log in</Link> or <Link to="/subscribe">Subscribe</Link></p>
			)}
			*/}

<h2 style={{marginTop: '40px', textDecoration: 'underline'}}>About me</h2>
<p style={{marginTop: "40px"}}>I am Haniah. I love art and I love thinking about what makes us do the things we do. That got me into advertising - which is where I have been for the last 20 years.</p>
<p>Connect with me on LinkedIn: <a style={{textDecoration: "underline"}} href="https://www.linkedin.com/in/haniahomar/">Haniah on LinkedIn</a></p>
		</div>
	</div>

	);
}

export default HomePage;
