import React, { useState, useEffect } from 'react';
import { useParams , Link } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';

function PostPage({db, user}) {
	const [postContent, setPostContent] = useState("");
	const [postTitle, setPostTitle] = useState("");
	const [postAuthor, setPostAuthor] = useState("");
	const [postDate, setPostDate] = useState("");
	const [userIsPaidSubscriber, setUserIsPaidSubscriber] = useState(false);
	const [isSubscriberOnly, setIsSubscriberOnly] = useState(false);
	const [premiumAvailable, setPremiumAvailable] = useState(false);
	const {urlStub} = useParams();

	useEffect(() => {
		async function fetchPost() {
			const postsRef = collection(db, 'posts');
			const q = query(postsRef, where('url-index', '==', urlStub));
			const querySnapshot = await getDocs(q);

			if(!querySnapshot.empty) {
				const postDoc = querySnapshot.docs[0].data();
				setIsSubscriberOnly(postDoc.subscriberOnly);
				setPremiumAvailable(postDoc.premiumAvailable);
				setUserIsPaidSubscriber(false);
				if(!postDoc.subscriberOnly || (user && user.isPaidSubscriber)) {
					setPostContent(postDoc.content);
					setPostTitle(postDoc.title);
					setPostAuthor(postDoc.author);
					setPostDate(postDoc.postDate?.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', }));
				} else {
					setPostContent('This content is for subscribers only.')}
			} else {
				setPostContent('Post not found');
			}
		}

		fetchPost();
	}, [db, urlStub, user]);






			return (
				<article className="article">
				<h1 className="title">{postTitle}</h1>
				<div className="content" dangerouslySetInnerHTML={{__html: postContent}} />
				<div className="metaData">
				<p><span className="author">{postAuthor}</span></p>
				<p><span className="author">Haniah Omar</span></p>
				<span className="date">{postDate}</span>
				</div>
				</article>
			);
	}

	export default PostPage;

